@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@500&display=swap');

@font-face {
  font-family: 'serifiyan';
  src: url(./assets/fonts/serifiyan.otf);
}

*{
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
}


button{
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.app {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: white;
  // background-color: black;
}



//////\\\\\\
//font-family
//////\\\\\\

.ffam-roboto{
  font-family: 'Roboto',sans-serif;
}
.ffam-quick{
  font-family: 'Quicksand',sans-serif;
}
.ffam-egypt{
  font-family: 'Egyptian Nights',sans-serif;
}
.ffam-poppins{
  font-family: 'Poppins',sans-serif;
}
.ffam-nunito{
  font-family: 'Nunito',sans-serif;
}
.ffam-book{
  font-family: 'avenir-book',sans-serif;
}
.ffam-roman{
  font-family: 'avenir-roman',sans-serif;
}
.ffam-avenir-black{
  font-family: 'avenir-black',sans-serif;
}
.ffam-public{
  font-family: 'Public-Sans',sans-serif;
}
.ffam-serifiyan{
  font-family: 'Serifiyan',sans-serif;
}

//////\\\\\\
//font-size
//////\\\\\\
.fs-xl {
  font-size: 4.9rem;
}
.fs-xlm {
  font-size: 4.185rem;
  line-height: 100%;
}
.fs-xls {
  font-size: 3.7rem;
  line-height: 100%;
}
.fs-l {
  font-size: 3.1rem;
  line-height: 123%;
}
.fs-welcome-title{
  font-size: 2.4rem;
  line-height: 105%;
}
.fs-ls{
  font-size: 2.4rem;
  line-height: 115%;
}
.fs-m {
  font-size: 1.6rem
}
.fs-ml{
  font-size: 1.43rem
}
.fs-ms {
  font-size: 1.125em;
  line-height: 171%;
}
.fs-s {
  font-size: 1rem;
  line-height: 175%;
}
.fs-header{
  font-size: 1rem;
}
.fs-xs {
  font-size: 0.95rem;
}
.fs-welcome-p-font{
  font-size:0.95rem
}
.fs-xxs {
  font-size: 0.66rem;
}

@media screen and (max-width:1100px) {
  .fs-l{
    font-size: 2.4rem;
  }
}

@media screen and (max-width:500px) {
  .fs-l{
    font-size: 7.5vw;
  }
  .fs-xs{
    font-size: 0.9rem;
  }
  .fs-header{
    font-size: 0.85rem;
  }
  .fs-m{
    font-size: 1.2rem;
    line-height: 100%;
  }
  .fs-xxs {
    font-size: 0.55rem;
  }
  .fs-welcome-p-font{
    font-size:0.8rem
  }
}

//////\\\\\\
//font-color
//////\\\\\\
.fc-white {
  color: #FFFFFF;
}
.fc-black {
  color: #000;
}
.fc-gray{
  color:#262626;
}
.fc-l-gray{
  color:#282828;
}


//////\\\\\\
//font-weight
//////\\\\\\
.fw-black {
  font-weight: 900;
}
.fw-extra-bold {
  font-weight: 800;
}
.fw-bold {
  font-weight: 700;
}
.fw-semi-bold {
  font-weight: 600;
}
.fw-med{
  font-weight: 500;
}
.fw-reg {
  font-weight: 400;
}
.fw-light {
  font-weight: 300;
}

.text-underlined{
  text-decoration: underline;
}



//////\\\\\\
//animation-classes
//////\\\\\\

.pre-animate-top{
  // position: relative;
  // top:200px;
  opacity: 0;
  transition:top 400ms ease-in-out,opacity 400ms ease-in-out;

}
.pre-animate-left{
  // position: relative;
  // left:200px;
  opacity: 0;
  transition:left 400ms ease-in-out,opacity 400ms ease-in-out;
}
.slide-to-top{
  // top:0px;
  // left:0px;
  opacity: 1;
}
.indented-section{
  display: flex;
  margin-top: 0.4rem;
  :nth-child(1){
      margin-right: 5px;
  }
}
