.carousel{
    position: relative;
    width: 100vw;
    height:fit-content;
    box-sizing: content-box;
    margin: 68px 0 0 0 ;
    overflow: hidden;
    
}
.carousel-logo-wrapper{
    height: 110px;
    max-height: 14vh;
    width: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    img{
        height: 100%;
    }
}
.carousel-inner{
    opacity: 0.85;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.carousel-wrapper{
    position: relative;
    overflow: hidden;
    width:100%;
    // height: 650px;
    height: 90vh;

    // max-height: 85vh;
    padding-top: 10px;
}
.carousel-track{
    position: absolute;
    display: flex;
    height: 100%;
    width: fit-content;
}
#carousel-track-1{
    left:0;
}
#carousel-track-2{
   
    right:0;
    top:calc(30% + 25px)
}
#carousel-track-3{
    left:0;
    top:calc(60% + 40px)
}

.carousel-img{
    height: 30%;
    margin-right: 15px;
    border-radius: 14px;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);
    // filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width:500px) {
    .carousel{
        margin: 74px 0 0 0 ;
        height: 90vh;
    }
    .carousel-logo-wrapper{
        height: 20vw;
      
    }
}