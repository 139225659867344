$lime-green:#BDFF00;

.nft-intro{
    width: 100vw;
    margin-top: 8rem;
}
.nft-intro-container{
    width: 1200px;
    max-width: 90vw;
    margin: auto;
    
}
.nft-intro-inner{
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: auto;
    background-color: white;
    justify-content: space-between;
}
.nft-intro-title{
    background: linear-gradient(90deg, #4cc2ef, #3909c8);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip:text;
    margin:1.3rem 0 0rem 0;
}

.nft-intro-left{
    width: 49.5%;
    height: fit-content;
}

.nft-intro-level-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.nft-intro-level{
    display:flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);
    padding: 10px;
    border-radius: 14px;
}
.nft-intro-paragraph{
    margin: 0.7rem 0 1.3rem 0;
    text-align: justify;
    a{
        color: black;
    }
}

.nft-intro-right{
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    justify-content: space-between;
    // background-color: blue;
}

.nft-intro-level-img-wrapper{
    
    width: 42%;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);
    border-radius: 20px;
    overflow: hidden;
    margin: 3.8%;
    img{
        width: 100%;
        // margin-bottom: -14px;
    }
    div{
        position: relative;
        width: 100%;
        // background-color: rgba(0,0,0,0.13);
        background-color: #334e97;
        text-align: center;
        padding:6px;
        box-sizing: border-box;
        font-size: 14px;
    }
}

@media screen and (max-width:1320px) {
    .nft-intro-left{
        width: 57%;
    }
    .nft-intro-right{
        width: 40%;
    }
}
@media screen and (max-width:1120px) {
    .nft-intro-title{
        margin:0 0 0rem 0;
    }
    .nft-intro-left{
        width: 60%;
    }
    .nft-intro-right{
        width: 37%;
    }
    .nft-intro-level-img-wrapper{
        margin: 2%;
        width: 46%;
        div{
            font-size: 12px;
        }
    }
}
@media screen and (max-width:1000px) {
    .nft-intro{
        width: 100vw;
        margin-top: 6rem;
    }
    .nft-intro-container{
        width: 410px;
        height: fit-content;
    }
    .nft-intro-inner{
        flex-direction: column;
        // padding: 1.5rem;
        box-sizing: border-box;
        text-align: center;
    }
    .nft-intro-left{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }
    .nft-intro-right{
        
        width: 104%;
        div{
            font-size: 0.8rem;
        }
    }
    .nft-intro-title{
        margin:1.7rem 0 0rem 0;
    }
   
    .nft-intro-level-wrapper {
        width: 104%;
        flex-wrap: wrap;
    }
    .nft-intro-level{
        width: 41%;
        margin: 1.55%;
    }
}