.footer {
    display: flex;
    align-items: center;
    width: 100vw;
    margin: 8rem 0 5rem 0;
    text-shadow: none;
}

.footer-inner {
    margin: auto;
    width: 1200px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.19);
    border-radius: 21px;
    padding: 25px 50px 25px 50px;
    box-sizing: border-box;
    background-color: white;
}

.footer-left {
    display: flex;
    height: 100%;
    width: 160px;
    text-align: start;
    align-items: flex-end;
}

.footer-middle {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 250px;

    }
}

.footer-middle-bottom {
    display: flex;
    justify-content: space-between;
    width: 115px;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .footer-social-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;

        border-radius: 50%;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);

        img {
            height: 30px;
            width: 30px;
        }
    }
}

#linktree-logo {
    width: 22px;
    height: 22px;
}

.footer-right {
    display: flex;
    align-items: end;
    height: 100%;
}

.footer-right-column-1,
.footer-right-column-2 {
    display: flex;
    flex-direction: column;
    text-align: start;

    a {
        text-decoration: underline;
        cursor: pointer;
        margin-top: 5px;
    }
}

.footer-right-column-1 {
    margin: 0px 2rem 0px 0px;
}

.footer-copy-mobile {
    display: none;
}

@media screen and (max-width:500px) {
    .footer {
        // margin: 4rem 0 3rem 0;
        margin: 0;
        padding-top: 4rem;
        padding-bottom: 2rem;

    }
}