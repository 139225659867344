$lime-green: #BDFF00;

.header {
    // padding-top: 10px;
    width: 100vw;
    height: 68px;
    position: fixed;
    left: 0;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 0px 0px rgb(190, 190, 190);
    // transition: box-shadow 200ms ease-in-out;
}

.header-inner {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    max-width: 90vw;

}

.header-left {
    height: 100%;
    display: flex;
    align-items: center;
}

.header-desktop-logo-wrapper {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;

    img {
        height: 80%;
    }
}

.header-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    max-width: 42%;
    button {
        transition: all 100ms ease-in;
    }
}

#header-desktop-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    top: 2px;
    height: 30px;

    &:hover {
        .roadmap-drop-down-desktop {
            display: block;
            opacity: 1;
            top: 30px;
        }
    }
}

#header-desktop-dropdown-open-btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    height: 25px;
    color: black;

    img {
        height: 15px;
        margin-left: 5px;
    }
}

.roadmap-drop-down-desktop {
    position: absolute;
    display: none;
    opacity: 0;
    top: 40px;
    width: 115px;
    height: 5;
    border-radius: 0 0 5px 5px;
    background-color: white;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.18);
    border-radius: 5px;

    button {
        width: 105px;
        height: 32px;

        padding-left: 10px;
        text-align: start;
        font-weight: bold;

        &:hover {
            color: black
        }
    }
}

.header-right {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-mint-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 41px;
    // background-color: rgba(0,0,0,0.13);
    color: white;
    background: linear-gradient(90deg, #4cc2ef, #7e78d8);
    box-shadow: 0px 0px 6px rgb(190, 190, 190);

    border-radius: 5px;
    margin-left: 1.5rem;
    transition: all 100ms ease-in;

    img {
        margin-left: 5px;
    }

    &:hover {
        background: white;
        color: black;
    }

    filter:blur(0.03rem)
}

.header-hamburger {
    position: absolute;
    display: none;
    right: 0;
    z-index: 20;
    opacity: 1;
    transition: opacity 320ms ease-in-out;
}

#header-hamburger-close {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    height: 30px;
    width: 30px;
    background: rgba(255, 255, 255, 0.65);
    border: 1px solid rgba(255, 255, 255, 0.51);
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    opacity: 0;

}

.header-mobile-nav {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 15;
    opacity: 0;
    background: linear-gradient(96.76deg, #F6FD7C 3.92%, #43F987 47.92%, #21F2D0 69.91%, #21CCF2 85.45%, #6A94FF 97.77%);
    overflow-y: scroll;
    transition: opacity 320ms ease-in-out;

}

.header-mobile-nav-top {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    margin: auto;
}

.header-mobile-nav-inner {
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
}

.header-mobile-logo {
    margin-bottom: 1rem;
    height: 100px;
}

.header-mobile-nav-btn {
    margin: 0 0 1.7rem 0;
    color: black;

}

.header-mobile-wallet-btn {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 297px;
    height: 50px;
    background-color: white;
    border: 3px solid #FC60FF;
    border-radius: 25px;
    margin: 1.5rem 0 0 0;
    font-size: 1.2rem;

    img {
        margin-left: 0.6rem;
    }

    color: black;
}

.header-mobile-nft-img {
    position: absolute;
    bottom: 0;
    margin-left: 7vw;
}

.header-mobile-logo-wrapper {
    cursor: pointer;
    height: 120px;
    display: flex;
    align-items: center;
    // margin-left: -12px;

    img {
        position: relative;
        top: 5px;
        height: 67%;
    }
}

.header-mobile-dropdown {
    width: 100%;
    margin: 0 0 1rem 0
}

.header-mobile-dropdown-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    height: 30px;
    color: black;

    img {
        height: 20px;
        transition: transform 300ms ease-in-out;
    }
}

.header-mobile-dropdown-bottom {
    width: 100%;
    height: 0px;
    overflow: hidden;

    button {
        text-align: start;
        height: 45px;
        width: 100%;
        padding-left: 15px;
        color: black;
    }

    transition: height 300ms ease-in-out;
}

.header-mobile-twitter-btn {
    height: 40px;

    img {
        height: 100%;
    }
}

// @media screen and (max-width:1350px) {
//     .header-middle {
//         width: 62%;
//     }
// }

@media screen and (max-width:1100px) {

    .header {
        // padding-top: 5px;
        // padding-bottom: 5px;
        
    }

    .header-inner {
        
        // flex-direction: column;
    }

    .header-middle{
        width: 70%;
        max-width: unset;
    }
    .header-left {
     

        img {
            height: 49px;
        }

        span {
            display: none;
        }
    }

    // .header-left{
    //     position: absolute;
    //     width: fit-content;
    //     margin: auto;
    //     left: 0;
    //     right: 0;
    //     img{
    //         height: 65px;
    //     }
    //     .header-left-text{
    //         display: none;
    //     }
    // }
    .header-middle {
        margin-top: 15px;
    }

    .header-right {
        display: none;
    }

    .header-hamburger {
        display: flex;
    }

    .header-mobile-logo {
        display: block;
    }
}

// @media screen and (max-height:800px) {

//     .header-mobile-nav-btn {
//         margin: 0 0 1.1rem 0;
//     }

//     .header-mobile-nft-img {
//         display: none;
//     }
// }

// @media screen and (max-width:500px) {

//     .header-mobile-nft-img {
//         margin: auto;
//         left: 0;
//         right: 0;
//     }

//     .header-mobile-wallet-btn {
//         width: 100%;
//         height: 55px;
//         border-radius: 35px;
//     }

//     #header-hamburger-close {
//         right: 2vw
//     }

// }