$gold: #c3a159;

.mint-section {
    position: relative;
    width: 100vw;
    animation: emerge 1s ease-in-out;
    margin-top: 8rem;
}

.mint-section-inner {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin: auto;
    max-width: 90vw;
    width: 1200px;
    // background-color: red;
}
.mint-section-container{

    width: 40%;
    height: fit-content;
    max-width: 90vw;
    // margin: auto;
}
.mint-paragraph{
    width: 50%;
    text-align: justify;
}
.mint-section-nft-video {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);

    overflow: hidden;
    img {
        width: 100%;
    }
}

.mint-section-mint-wrapper {
    width: 100%;
}

.mint-section-mint-amount-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 63px;
    width: 100%;
    border-radius: 6px;
    padding: 0 8px 0 7px;
    box-sizing: border-box;
    max-width: 90vw;
    margin: 1rem 0 1rem;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);
}

.mint-section-mint-amount-btn {
    height: 47px;
    width: 47px;
    color: white;
    cursor: pointer;
    font-size: 1.9rem;
    line-height: 45px;
    border-radius: 6px;
    background: linear-gradient(90deg, #4cc2ef, #7e78d8);
    box-shadow: 0px 0px 6px rgb(190, 190, 190);

    transition: background 200ms ease-in-out, color 200ms ease-in-out;

    &:hover {
        background: white;
        color: black;
    }
}

.mint-section-mint-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    width: 100%;
    border-radius: 5px;
    max-width: 90vw;
    box-shadow: 0px 0px 6px rgb(190, 190, 190);
    background: linear-gradient(90deg, #4cc2ef, #7e78d8);
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
    &:hover {
        background: white;
        span {
            color: black;
        }
    }
}

.mint-title{
    background: linear-gradient(90deg, #4cc2ef, #3909c8);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip:text;
    // text-align: center;
    width: 100%;
    margin-bottom: -1rem;
    // margin:1.3rem 0 0rem 0;
}

.loading-button {
    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        span {
            color: white !important
        }
    }
}
@media screen and (max-width:1000px) {
    .mint-section {
        margin-top: 3rem;
    }
    .mint-section-inner {
        width: 410px;
        flex-direction: column-reverse;
    }
    .mint-section-container{
        width: 100%;
        margin-top: 3rem;
    }
    .mint-paragraph{
        width: 100%;
        text-align: justify;
    }
    .mint-title{
        text-align: center;
    }
}
@media screen and (max-width:500px) {
    .mint-section {
        margin-top:2rem;
    }
    .mint-section-container{
        margin-top: 2rem;
    }
}