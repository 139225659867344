.roadmap-page{
    padding-top:4rem;
    // padding-top:2.5rem;

    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    // background-color: black;
}
.roadmap-page-inner{
    display: flex;
    justify-content: center;
    width: fit-content;
    height: 100% ;
    max-width: 90vw;
    max-height: 1000px;
    margin: auto;
    // background-color: red;
}
.roadmap-page-bg-img{
    // width: 100%;
    height: 100%;
    background-color: white;
    // background-color: blue;
}
.roadmap-title{
    background: linear-gradient(90deg, #2a8bb1, #3909c8);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip:text;
    margin:0 0 -0.5rem 0;
    margin-left:2rem;
    line-height: 22px;
 
}
.roadmap-page-bg-img-mobile{
    height:unset;
    width: 99vw;
    height: calc(99vw / 0.573);
    display: none;
}
.roadmap-page-text{
    position: absolute;
    width: 340px;
    margin-top: 31.2vh;
    font-weight:bold;
    // width: ;
    font-size: 19px;
    line-height: 18px;
}
.roadmap-text-divider{
    margin-bottom: 0.55rem;
}
.roadmap-empty-check-box{
    height: 15px;
    width: 15px;
    border: 1px solid black;
    border-radius: 2px  ;
}
.tick-box{
    font-size: 1.7rem;
    // background-color: blue;
    // line-height: 1px;
    margin-left: -2px;
    margin-right: -2px;
}
.roadmap-indented-section{
    width: 340px;
    margin-left: -35px;
    // background-color: red;
}
@media screen and (max-width:450px){
    .roadmap-page{
        padding-top:4.4rem;
    }
    .roadmap-page-bg-img{
        height: 102.5%;
    }
}
// @media screen and (max-width:500px) {
//     .roadmap-page-bg-img-mobile{
//         display: block;
//     }
//     .roadmap-page-bg-img{
//         display: none;
//     }
// }
