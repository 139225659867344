.welcome-section {
    width: 100vw;
    min-height: 100vh;
    padding-top:7rem;
    box-sizing: border-box;
    animation: appear 1.5s ease-in-out;
    background: linear-gradient(90deg, #4cc2ef, #7e78d8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-shadow: 1px 1px rgb(82, 82, 82);
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.welcome-section-inner{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    max-width: 90vw;
    margin: auto;
    // background-color: red;
  
}
.welcome-bottom-paragraph{
    width: 1200px;
    max-width: 90vw;
    margin: auto;
    text-align: justify;
    margin-bottom: -4rem;
}
.welcome-title{
    text-align: center;
}
.welcome-paragraph{
    text-align: justify;
}
.welcome-statement{
    width: 57%;
    max-width: 90vw;
    // text-align: center;
    line-height: 140%;

    margin-bottom: 2rem;
   
}
.indented-section{
    display: flex;
    text-align: start;
    width: 80%;
    margin-left: 1.5rem;
    :nth-child(1){
        margin-right: 5px;
    }
}
.card-video-wrapper{
    position: relative;
    // background-color: red;
    // height: 400px;
    width: 400px;
    max-width: 90vw;
}
.card-video{
    width: 100%;   
    border-radius: 20px;
    position: relative;
   
}

@media screen  and (max-width:1200px){
    .welcome-section {
     
        display: block;
        flex-direction: unset;
        justify-content: unset;
    }
    .welcome-section-inner{
        flex-direction: column-reverse;
        width: 450px;
      
    }
    .welcome-bottom-paragraph{
        width: 450px;
    }
    .welcome-statement{
        width: 100%;
    }
    .card-video-wrapper{
        width: 100%;
        margin: 0px 0 3rem 0;
    }
}
@media screen and (max-width:500px) {
    .welcome-bottom-paragraph{
   
    
        margin-bottom: 0;
    }
    .welcome-section {
        padding-top: 4rem;
    }
    .welcome-title{
        text-align: center;
    }
    .indented-section{
        width: 94%;
        margin-left: 1rem;
    }
}