.landing-page {
   
}
.landing-page-top-section{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.landing-logo {
    position: absolute;
    height: 120px;
    left: 0;
    right: 0;
    margin: auto;
}

.landing-page-inner {
    position: relative;
    max-width: 100vw;
    width: calc(100vh / 0.5625);
    margin: auto;
}

.landing-page-img-wrapper {
    position: relative;
    width: 100vw;
    //     width: calc(100vh / 0.5625);

    height: 100vh;
    max-width: 100vw;
}

.landing-img {
    height: 100%;
    width: 100%;
}

.landing-video {
    height: 100%;
    width: 100%;
}

.landing-page-enter-wrapper {

    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: fixed;
    bottom: 2vh;
    left:0;
    right: 0;
    // top: 3rem;
    margin: auto;
    // width:420px;
    width: 450px;

    max-width: 90vw;
    height:230px;
    // height: 70px;

    // padding: 2rem;
    box-sizing: border-box;
    // background-color: rgba(0, 0, 0, 0.6);
    border-radius: 15px;

    p {
        font-size: 1.8rem;
        z-index: 1;
    }

    background-color: rgba(0, 0, 0, 0.3);

}


// .landing-page-enter-wrapper-background{
//     top:0px;
//     position: absolute;

//     width:360px;
//     height: calc(360px * 0.7115);
//     // background-color: blue;
//     background-color: rgba(0, 0, 0, 0.3);

//     border-radius: 15px;

// }
.landing-page-border {
    position: absolute;
    mix-blend-mode: screen;

    height: calc(360px * 0.7115);
    width: 360px;
}

.landing-btn {
    position: relative;
    // margin: 2rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 55px;

    border-radius: 10px;
    color: white;
    cursor: pointer;
    background: linear-gradient(90deg, #4cc2ef, #7e78d8);

    animation: btn-animation 2300ms linear infinite;

    // transition:background-color 200ms ease-in-out, color 200ms ease-in-out;
    &:hover {
        background: white !important;
        color: black
    }
}

// @keyframes btn-animation {
//     0%{
//         background-color: #f89b0f;
//     }
//     50%{
//         background-color: #faad3a;
//     }
//     100%{
//         background-color: #f89b0f;
//     }
// }

.landing-social-wrapper {
    display: flex;
    align-items: center;
    // background-color: red;
    margin: 0.1rem 0 0.5rem 0;
    z-index: 1;
}

.landing-social-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    margin: 0.5rem;
    cursor: pointer;
    // img{
    //     // height: 90%;
    // }
    border-radius: 50%;
    // background-color: white;
}



.landing-social-wrapper-border {
    width: 67px;
    height: 2px;
    background-color: white;
    margin: 1rem 0 1rem 0;
    animation: landing-animation 2500ms linear infinite;

}

.landing-page-bottom-animation {
    display: none;
    position: absolute;
    width: 170vw;
    height: 100px;
    bottom: -20px;
}

.top-section-audio {
    position: absolute;
    bottom: 10px;
    right: 6px;
    cursor: pointer;
    z-index: 10;
}

#top-section-playing {
    height: 35px !important;
}

@media screen and (max-width:calc(100vh / 0.667)) {
    // .landing-page-inner {
    //     height: calc(100vw * 0.667);
    // }

    // .landing-img {
    //     height: calc(100vw * 0.667);
    //     width: unset;
    // }
}

@media screen and (max-width:700px) {
    .landing-page-enter-wrapper{
        display: flex;
    }
    .landing-page-enter-wrapper {
        position: relative;
        flex-shrink: 0;
        bottom: unset;
        top: 1.1rem;
    }


    .landing-page-bottom-animation {
        display: flex;

    }

    .landing-page-enter-wrapper {

        p {
            font-size: 1.3rem;
        }

    }

    .top-section-audio {

        bottom: 85px;

    }

    .landing-page {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
    }

}

@media screen and (max-width:500px) {
    .landing-page-img-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .landing-img {
        flex-shrink: 0;
        height: calc(130vw * 0.667);
        width: unset;
        margin-left: 36vw;
    }
}