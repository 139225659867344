$pink:#E4A7AF;
.team-page{
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    animation: emerge-team 1.5s ease-in-out;

}
@keyframes emerge-team {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.team-about-us{
    margin: auto;
    margin-top: 6rem;
    width: 950px;
    max-width: 90vw;
    text-align: center;
    // background-color: red;
}
.team-about-paragraph{
    margin: 0.7rem 0 1.3rem 0;
    text-align: center;
    a{
        color: black;
    }
}
.team-about-title{
    background: linear-gradient(90deg, #4cc2ef, #3909c8);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip:text;
    margin:1.3rem 0 0rem 0;
}
.team-wrapper{
    height: 360px;
    display: flex;
    justify-content: space-between;
    perspective: 1500px;
    width: 1650px;
    max-width: 90vw;
    margin: auto;
    margin-top: 3rem;
}
.team-section{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 19.8%;
    height: 100%;
    background-color: black;
    overflow: hidden;
    transition: all 600ms ease-in-out;

    cursor: pointer;
    &:hover{
        
        .team-section-bottom{
          
            height: 100%;
            color:white;
            p{
                display:block;
            }
        }
    }
}
.team-section-initial{
    transition: all 600ms ease-in-out;

    height: 100%;
    width: 100%;
    opacity: 1;
}
.team-section-top{
    display: none;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 1rem;
    overflow: hidden;
    height: 76%;
    // background-color: #7F675C;
    img{
        width: 300px;
        height: 300px;
        // max-width: 90%;
        // background-color: red;
    }
}
.team-section-bottom{
    position: absolute;
    bottom:0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(51, 78, 151, 0.65);
    height: 24%;
    color:white ;
    p{
        display:none;
        flex: 1;
      

    }
    transition: all 100ms ease-in-out;
    text-align: center;
    padding: 0.9rem;
    box-sizing: border-box;
}
.team-section-name{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.team-section-revealed{
    height: 100%;
    display: none;
    padding: 0.9rem;
    box-sizing: border-box;
    p{
        margin-top: 0.3rem;
    }
    transform: rotateY(180deg);
    text-align: center;
    background-color: rgba(51, 78, 151, 0.65);
    color:white;
}
.team-section-bg-img{
    position: absolute;
    height:440px;
    transition: all 600ms ease-in-out;
    // width: 100%;
    // width: 280px;
    // left:0;
    // background-color: red;
}
// @media screen and (max-width:1040px) {
@media screen and (max-width:1250px) {

    .team-wrapper{
        height: fit-content;
       
        flex-wrap: wrap;
        // top: 100%;
        // position: absolute;
        
    }
    .team-section{
        width: 49.5%;
        height: 400px;
        margin: 0 0 0.6rem 0;
    //    background-color: red;
    }
    .team-section-bg-img{
        position: absolute;
        height:unset;
        transition: all 600ms ease-in-out;
        width: 100%;
        // width: 280px;
        // left:0;
        // background-color: red;
    }
    .team-section-top{
        img{
            width: 220px;
            height: 220px;
        }
    }
}
@media screen and (max-width:620px) {
    .team-wrapper{
        .fs-m{
            margin-bottom: 5px;
        }
    }
    .team-section-bg-img{
        position: absolute;
        height:100%;
        transition: all 600ms ease-in-out;
        width: unset;
    }
    .team-section-bottom{
        height: 18%;
    }
}